/*
#793f70 - purple
#7dffbe - green
#c1c1c1 background grey mid tone
#c5c5c5 background grey lighter
*/

@font-face {
  font-family: "Aquarius";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-Regular.ttf") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "Aquarius";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-Bold.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "Aquarius-Mono";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-RegularMono.ttf") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "Aquarius-SmallCap";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-RegularSmallCaps.ttf") format("truetype");
  font-weight: normal;
 }

 html {background-color: #c1c1c1}

 .resume-fullpage {
  align-items: center; 
  display: flex;
  flex-direction: column;
  height: 100vh; 
  background-color: #c1c1c1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.resume-block1 {
  flex-grow: 0; 
  width: 100vw;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding-left: 0%;
  padding-right: 0%;
  width: 100%;
  font-size: clamp(1vw, 1.6vw, 2.2vw); 
}

.nav-links li {
  flex: 1;
}

.nav-home {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color:white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-about {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-experience {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-projects {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-blog {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-resume {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.resume-block2 {
  width: 90vw;
  text-align: center;
  background-color: #ffffff;
  border: solid 3px black;
}

.resume-download {
  display: block;
  margin-left: 12vw;
  margin-top: 2em;
  font-family: 'Aquarius-SmallCap';
  font-size: 1.2vw;
  text-decoration: none;
  float: left;
  color: #000000;
  -webkit-text-stroke: 0.02em;
  -webkit-text-stroke-color: #ffffff; 
  &:hover {
    -webkit-text-stroke: 0.10em;
    -webkit-text-stroke-color: #7dffbe; 
  }
}

.resume-resume {
  width: 50vw;
  float: right;
  border-left: solid 1px black;
}

.resume-subtitle {
  font-family: 'Aquarius';
  font-weight: bold;
  font-size: 2.0vw;
  float: left;
  margin-left: 11vw;
  -webkit-text-stroke: 0.12vw;
  -webkit-text-stroke-color: #793f70;
  color: rgb(255, 255, 255);
}

.resume-paragraph {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
  margin-left: 5%;
  margin-right: 5%;
  color: rgb(0, 0, 0);
  font-size: 1vw;
  text-align: left;
  float: left;
}

.resume-cert-pt {
  width: 20vw;

}

.resume-cert-ml{
  width: 20vw;

}

.about-block3 {
  flex-grow: 0.1; 
  width: 90vw;
}

.resume-links {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding-left: 0%;
  padding-right: 0%;
  width: 100%;
  font-size: clamp(20px, 4vw, 30px);
}

.about-links a.button-github {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: #ffffff;
  -webkit-text-stroke: 0.08em;
  -webkit-text-stroke-color: #000000; 
  &:hover {
    -webkit-text-stroke: 0.08em;
    -webkit-text-stroke-color: #7dffbe; 
    cursor: url("../assets/gh_logo.png"), auto;
  }
}

.about-links a.button-linkedin {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: #ffffff;
  -webkit-text-stroke: 0.08em;
  -webkit-text-stroke-color: #000000; 
  &:hover {
    -webkit-text-stroke: 0.08em;
    -webkit-text-stroke-color: #7dffbe;  
    cursor: url("../assets/li_logo.png"), auto;
  }
}

.about-links a.button-email {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: #ffffff;
  -webkit-text-stroke: 0.08em;
  -webkit-text-stroke-color: #000000; 
  &:hover {
    -webkit-text-stroke: 0.08em;
    -webkit-text-stroke-color: #7dffbe; 
    cursor: url("../assets/em_logo.png"), auto;
  }
}

@media screen and (min-width: 800px) {
  .nav-links {
    font-size: 1.2vw; 
  }
  .about-paragraph {
    font-size: 1.2em;
  }
  .about-links {
    font-size: clamp(30px, 1.5vw, 1.5vw);
  }
}