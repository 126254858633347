/*
#793f70 - purple
#7dffbe - green
#1c1c1c background grey mid tone
#c5c5c5 background grey lighter
*/

@font-face {
  font-family: "Aquarius";
  src: local("Aquarius"),
   url("./fonts/Aquarius/Aquarius-Regular.ttf") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "Aquarius";
  src: local("Aquarius"),
   url("./fonts/Aquarius/Aquarius-Bold.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "Aquarius-Mono";
  src: local("Aquarius"),
   url("./fonts/Aquarius/Aquarius-RegularMono.ttf") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "Aquarius-SmallCap";
  src: local("Aquarius"),
   url("./fonts/Aquarius/Aquarius-RegularSmallCaps.ttf") format("truetype");
  font-weight: normal;
 }

 html {background-color: #c1c1c1}

 .home-fullpage {
  align-items: center; /* Align items to the top of the container */
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure the container takes the full viewport height */
  background: url("./assets/background.gif") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.home-block1 {
  flex-grow: 0; /* Do not let this block grow to take extra space */
  width: 100vw;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding-left: 0%;
  padding-right: 0%;
  width: 100%;
  font-size: clamp(1vw, 1.6vw, 2.2vw); /*1.6vw, 1.5vi*/
}

.nav-links li {
  flex: 1;
}

.nav-home {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color:white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-about {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-experience {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-projects {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-blog {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-resume {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.home-block2 {
  flex-grow: 1; /* Let this block grow to fill available space */
  width: 100vw;
  text-align: center;
}

.home-title {
  font-family: 'Aquarius';
  font-weight: bold;
  font-size: 5vw;
  -webkit-text-stroke: 0.35vw;
  -webkit-text-stroke-color: #793f70;
  color: rgb(255, 255, 255);
  /* text-shadow: 0px 0px 0.5vw #793f70; */
}

.home-subtitle {
  font-family: 'Aquarius';
  font-weight: bold;
  font-size: 3vw;
  -webkit-text-stroke: 0.24vw;
  -webkit-text-stroke-color: #793f70;
  color: rgb(255, 255, 255);
  /* text-shadow: 0px 0px 0.3vw #793f70; */
}

.home-paragraph {
  font-family: 'Aquarius';
  font-weight: bold;
  font-size: 3vw;
  -webkit-text-stroke: 0.24vw;
  -webkit-text-stroke-color: #793f70;
  color: rgb(255, 255, 255);
  /* text-shadow: 0px 0px 0.3vw #793f70; */
}

.home-block3 {
  flex-grow: 0.1; 
  width: 100vw;
}

.home-links {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding-left: 0%;
  padding-right: 0%;
  width: 100%;
  font-size: clamp(20px, 4vw, 30px);
}

.home-links a.button-github {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: #ffffff;
  -webkit-text-stroke: 0.08em;
  -webkit-text-stroke-color: #000000; 
  &:hover {
    -webkit-text-stroke: 0.06em;
    -webkit-text-stroke-color: #7dffbe; 
    cursor: url("./assets/gh_logo.png"), auto;
  }
}

.home-links a.button-linkedin {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: #ffffff;
  -webkit-text-stroke: 0.08em;
  -webkit-text-stroke-color: #000000; 
  &:hover {
    -webkit-text-stroke: 0.06em;
    -webkit-text-stroke-color: #7dffbe; 
    cursor: url("./assets/li_logo.png"), auto;
  }
}

.home-links a.button-email {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: #ffffff;
  -webkit-text-stroke: 0.08em;
  -webkit-text-stroke-color: #000000; 
  &:hover {
    -webkit-text-stroke: 0.06em;
    -webkit-text-stroke-color: #7dffbe; 
    cursor: url("./assets/em_logo.png"), auto;
  }
}

@media screen and (min-width: 800px) {
  .nav-links {
    font-size: 1.2vw; /* Adjust these values as needed */
  }
  .home-title {
    font-size: 3vw;
    -webkit-text-stroke: 0.21vw;
    -webkit-text-stroke-color: #793f70;
  }
  .home-subtitle {
    font-size: 2vw;
    -webkit-text-stroke: 0.18vw;
    -webkit-text-stroke-color: #793f70;
  }
  .home-paragraph {
    font-size: 2vw;
    -webkit-text-stroke: 0.18vw;
    -webkit-text-stroke-color: #793f70;
  }
  .home-links {
    font-size: clamp(30px, 1.5vw, 1.5vw);
  }
}