/*
#793f70 - purple
#7dffbe - green
#c1c1c1 background grey mid tone
#c5c5c5 background grey lighter
*/

@font-face {
  font-family: "Aquarius";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-Regular.ttf") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "Aquarius";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-Bold.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "Aquarius-Mono";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-RegularMono.ttf") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "Aquarius-SmallCap";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-RegularSmallCaps.ttf") format("truetype");
  font-weight: normal;
 }

 .projpost-fullpage {
  align-items: center; /* Align items to the top of the container */
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure the container takes the full viewport height */
  background-color: #ffffff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.projpost-block1 {
  flex-grow: 0; /* Do not let this block grow to take extra space */
  width: 100vw;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding-left: 0%;
  padding-right: 0%;
  width: 100%;
  font-size: clamp(1vw, 1.6vw, 2.2vw); /*1.6vw, 1.5vi*/
}

.nav-links li {
  flex: 1;
}

.nav-home {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color:white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-about {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-experience {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-projects {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-blog {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-resume {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.projpost-block2 {
  width: 90vw;
  background-color: #ffffff;
  border: solid 3px black;
}

.backlink {
  width: auto;
  display: block;
  margin-top: 5px;
  margin-left: 5px;
  text-align: left;
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: #000000;
  -webkit-text-stroke: 0.02em;
  -webkit-text-stroke-color: #ffffff; 
  &:hover {
    -webkit-text-stroke: 0.10em;
    -webkit-text-stroke-color: #7dffbe; 
  }
}

.projtitle {
  width: auto;
  display: block;
  margin-top: 5px;
  margin-left: 5px;
  text-align: center;
  font-family: 'Aquarius';
  font-weight: bold;
  -webkit-text-stroke: 0.12vw;
  -webkit-text-stroke-color: #793f70;
  color: rgb(255, 255, 255);
  font-size: 3vw;
}

.projpost-paragraph {
  margin: 2em;
}

/* .blogpost-image {

} */

@media screen and (min-width: 800px) {

}