/*
#793f70 - purple
#7dffbe - green
#c1c1c1 background grey mid tone
#c5c5c5 background grey lighter
*/

@font-face {
  font-family: "Aquarius";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-Regular.ttf") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "Aquarius";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-Bold.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "Aquarius-Mono";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-RegularMono.ttf") format("truetype");
  font-weight: normal;
 }

 @font-face {
  font-family: "Aquarius-SmallCap";
  src: local("Aquarius"),
   url("../fonts/Aquarius/Aquarius-RegularSmallCaps.ttf") format("truetype");
  font-weight: normal;
 }

 html {background-color: #c1c1c1}

 .exper-fullpage {
  align-items: center; /* Align items to the top of the container */
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure the container takes the full viewport height */
  background-color: #c1c1c1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.exper-block1 {
  flex-grow: 0; /* Do not let this block grow to take extra space */
  width: 100vw;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding-left: 0%;
  padding-right: 0%;
  width: 100%;
  font-size: clamp(1vw, 1.6vw, 2.2vw); /*1.6vw, 1.5vi*/
}

.nav-links li {
  flex: 1;
}

.nav-home {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color:white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-about {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-experience {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-experects {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-blog {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.nav-resume {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  color: white;
  display: block;
  border-block: solid;
  background-color: #793f70;
  text-align: center;

  &:hover {
    background-color: #7dffbe;
  }
}

.exper-block2 {
  width: 90vw;
}

.posts {
  display: grid;
  grid-template-columns: repeat(1, 1fr);/* Two columns when wide enough, one column when narrow */
  gap: 10px; /* Adjust the gap between posts */
}

.post-box {
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  border: solid 3px black;
  height: fit-content;

  &:hover {
    background-color: #7dffbe;
    border: solid 3px #ffffff;
  }
}

.post-title {
  font-family: 'Aquarius-SmallCap';
  text-decoration: none;
  font-size: 1em;
  margin-top: 1dvh;
  margin-left: 2dvw;
  margin-right: 2dvw;
  display: block;
  color: #793f70;
  text-align: center;
}

.post-preview {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
  margin-left: 4%;
  margin-right: 4%;
  color: rgb(0, 0, 0);
  font-size: 0.8em;
  text-align: left;
  text-decoration: none;
  margin-top: 1dvh;
  display: block;
}

.post-imgcase {
  align-items: center;
  width: 100%;
}

.post-thumbnail {
  display: block;
  background-color: rgb(255, 255, 255);
  width: 100%;
  object-fit: fill;
  border: solid 0px rgb(255, 255, 255);
  border-radius: 0px 0px 16px 16px;
}

@media screen and (min-width: 800px) {
  .nav-links {
    font-size: 1.2vw; /* Adjust these values as needed */
  }

  .posts {
    grid-template-columns: repeat(2, 1fr);/* Two columns when wide enough, one column when narrow */
    gap: 15px;
  }

  .post-title {
    font-size: 1.2em;
  }

  .post-preview {
    font-size: 0.9em;
  }

}